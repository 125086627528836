<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">推送列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="通知标题" class="searchboxItem ci-full">
              <span class="itemLabel">通知标题:</span>
              <el-input
                v-model="noticeTitle"
                type="text"
                size="small"
                placeholder="请输入通知标题"
                clearable
              />
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">通知状态:</span>
              <el-select
                size="small"
                v-model="noticeState"
                clearable
                placeholder="请选择通知状态"
              >
                <el-option
                  v-for="item in noticeStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="doAdd('add')"
                >新增</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="doToVoid()"
                >作废</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              row-key="noticeId"
          @selection-change="checkListData"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                type="selection"
                width="50px"
                align="center"
                fixed
                :reserve-selection="true"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="通知标题"
                align="center"
                show-overflow-tooltip
                prop="noticeTitle"
              />
              <el-table-column
                label="目标用户数"
                align="left"
                show-overflow-tooltip
                prop="targetNum"
              />
              <el-table-column label="发送数量" align="center" prop="sendNum" />
              <el-table-column
                label="送达数量"
                align="center"
                prop="serviceNum"
              />
              <el-table-column
                label="点击数量"
                align="center"
                prop="clickNum"
              />
              <el-table-column label="送达率" align="center" prop="servicePercent">
                <template slot-scope="scope">
                    {{scope.row.servicePercent}}%
                </template>
                </el-table-column>

              <el-table-column label="点击率" align="center" prop="clickPercent" >
                <template slot-scope="scope">
                    {{scope.row.clickPercent}}%
                </template>
                </el-table-column>

              <el-table-column label="状态" align="center" prop="noticeState">
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "EDU_MSG_NOTICE_STATE",
                      scope.row.noticeState
                    )
                  }}
                </template>
              </el-table-column>
                <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 15px"
                    size="mini"
                    @click="doAdd(scope.row.noticeState!= '10'? 'look':'edit', scope.row.noticeId)"
                  >{{scope.row.noticeState!= '10'? '查看':'编辑'}}</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "limitCompConfig",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      noticeTitle: "", //通知标题
      noticeState: "", //通知状态
      noticeStateList: [], //通知状态下拉数据
      multipleTable:[],
    };
  },
  created() {
    this.getNoticeState();
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.noticeTitle) {
        params.noticeTitle = this.noticeTitle;
      }
      if (this.noticeState) {
        params.noticeState = this.noticeState;
      }
      this.doFetch({
        url: "/msg/notice/pageList",
        params,
        pageNum,
      });
    },
    //获取通知状态
    getNoticeState() {
      const list = this.$setDictionary("EDU_MSG_NOTICE_STATE", "list");
      for (const key in list) {
        this.noticeStateList.push({
          value: key,
          label: list[key],
        });
      }
    },
    doAdd(stu,noticeId='') {
      this.$router.push({
        path: "/web/operate/pushAdd",
        query:{
            stu,
            noticeId
        }
      });
    },
       checkListData(rowArr) {
      this.multipleTable = rowArr;
    },
    //作废
    doToVoid() {
         if (this.multipleTable.length > 0) {
        let noticeIds = [];
        noticeIds = this.multipleTable.map((t) => {
          return t.noticeId;
        });
          this.$post("/msg/notice/cancel", {
            noticeIds,
          })
            .then((res) => {
              if (res.status == 0) {
            this.getData(-1)
            this.$refs.multipleTable.clearSelection();
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh ||val.query.refresh == 'true' ) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>